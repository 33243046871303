<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <b-row class="mb-0">
                    <b-col cols="2">
                        <b-card-title>风险日历</b-card-title>
                        <heat-map :heatmap-height="240"></heat-map>
                    </b-col>

                    <b-col cols="10">
                        <div class="app-calendar">
                            <div id="calendar" class="full-calendar"></div>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import HeatMap from "@/views/front/cabr/diagnosis/risk/components/heatmap.vue";
import { getDiagnosisCalendar } from "@/api/diagnosis";

export default {
    name: "diagnosisRisk",
    components: { HeatMap },
    data() {
        return {}
    },
    mounted() {
        const getData = () => {
            let currentMonth = calendar.getDate().getMonth() + 1
            if (currentMonth < 10) {
                currentMonth = '0' + currentMonth
            }
            const currentYear = calendar.getDate().getFullYear()
            calendar.removeAllEvents()
            getDiagnosisCalendar({ year: currentYear, month: currentMonth }).then(res => {
                for (let i of res.data) {
                    calendar.addEvent(i)
                }
            })

        }
        let calendarEl = document.getElementById("calendar");
        let calendar = new Calendar(calendarEl, {
            dateClick() {
                console.log('aaa')
            },
            plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
            timeZone: "UTC",
            locale: "zh-cn",
            initialView: "dayGridMonth",
            themeSystem: "bootstrap5",
            eventOrderStrict: true,
            eventOrder: "order",
            // dayMaxEventRows: 3,
            dayMaxEvents: 1,
            events: [],
            eventClick: (info) => {
                info.jsEvent.preventDefault(); // don't let the browser navigate
                console.log(info, 'infoinfo')
                console.log(info.event._def.title)
                if (info.event._def.title.includes('风险数')) {
                    this.$router.push({
                        name: 'index-agent-list',
                        query: {
                            key: '风险数'
                        }
                    })
                } else if (info.event._def.title.includes('已处置')) {
                    this.$router.push({
                        name: 'index-agent-list',
                        query: {
                            key: '已处置'
                        }
                    })
                } else if (info.event._def.title.includes('已忽略')) {
                    this.$router.push({
                        name: 'index-agent-list',
                        query: {
                            key: '已忽略'
                        }
                    })
                } else if (info.event._def.title.includes('严重')) {
                    this.$router.push({
                        name: 'index-agent-list',
                        query: {
                            key: '严重'
                        }
                    })
                } else if (info.event._def.title.includes('提醒')) {
                    this.$router.push({
                        name: 'index-agent-list',
                        query: {
                            key: '提醒'
                        }
                    })
                }
                else if (info.event._def.title.includes('一般')) {
                    this.$router.push({
                        name: 'index-agent-list',
                        query: {
                            key: '一般'
                        }
                    })
                }
            },
            editable: true,
            selectable: true,
            customButtons: {
                prev: {
                    click: () => {
                        calendar.prev()
                        getData()

                    }
                },
                next: {
                    click: () => {
                        calendar.next()
                        getData()

                    }
                },
            },
            headerToolbar: {
                left: "title",
                //center: "dayGridMonth,timeGridWeek,listWeek",
                right: "prev,next,today",

            },
        });
        calendar.render();
        getData()
    },
};
</script>


<style lang="scss">
@import "app-calendar.scss";

.eventDanger {
    color: #ea5455 !important;

    .fc-daygrid-event-dot {
        border-color: #ea5455 !important;
    }

    .fc-event-time {
        display: none !important;
    }
}

.eventInfo {
    color: #ff9f43 !important;

    .fc-daygrid-event-dot {
        border-color: #ff9f43 !important;
    }

    .fc-event-time {
        display: none !important;
    }
}

.eventWarning {
    color: #00cfe8 !important;

    .fc-daygrid-event-dot {
        border-color: #00cfe8 !important;
    }

    .fc-event-time {
        display: none !important;
    }
}
</style>
